<template>
    <div class="menu-wrap">
        <el-menu :defaultActive="navIndex" @select="handleSelect" class="el-menu-vertical-demo" active-text-color="#49C265">
            <el-menu-item :index="m.id+''" :key="m.id" v-for="m in list">{{m.title}}</el-menu-item>
        </el-menu>
        <div class="content" v-if="list[navIndex]" v-html="list[navIndex].content"></div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                navIndex: "",
                list: {},
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            let id = this.$route.query.id;
            this.navIndex = id ? id + "" : "";
            this.setMeta("关于我们");
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getHelp();
        },
        methods: {
            // ...mapMutations([])
            checkPath(t) {
                let q = t.query;
                if (q.id) {
                    this.navIndex = q.id + "";
                }
            },
            async getHelp() {
                let _this = this;
                let {
                    code,
                    data
                } = await this.$y_ajax("api/help/question", {
                    cid: 9
                });
                if (code == 200) {
                    let obj = {};
                    data.forEach((v) => {
                        if (!_this.navIndex) {
                            _this.navIndex = v.id + "";
                        }
                        obj[v.id] = v;
                    });
                    this.list = obj;
                }
            },
            handleSelect(i) {
                this.navIndex = i + "";
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            $route(to, from) {
                // 路径判断
                this.checkPath(to);
            },
        },
    };
</script>

<style lang="less" scoped>
    .menu-wrap {
        display: flex;

        .el-menu-vertical-demo {
            width: 200px;
        }

        .content {
            box-sizing: border-box;
            flex: 1;
            min-height: 100vh;
            background: #fff;
            padding: 10px;
        }
    }

    /deep/.el-menu-item:focus,
    /deep/.el-menu-item:hover {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .menu-wrap {
            .el-menu-vertical-demo {
                width: 100px;
            }
        }
    }
</style>